<template>
  <div>
    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-card-title>
          Animateurs
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">
          <v-data-table
                  :headers="headers"
                  :items="animators"
                  class="table-border rounded-lg"
                  hide-default-footer
          >

            <template v-slot:item.name="{ item }">
              <div class="d-flex align-center py-2">

                <v-avatar v-if="item.photo" size="40">
                  <v-img :src="$baseUrl + item.photo"
                         class="zoom-pointer"
                         @click="$refs.lightbox.open(item.photo)"></v-img>
                </v-avatar>

                <v-avatar v-else
                          :class="$func.randomColor()"
                          class="font-weight-medium"
                          size="40">
                  {{ $func.avatarName(item.name) }}
                </v-avatar>

                <span class="ml-2">{{ item.name }}</span>
              </div>
            </template>

            <template v-slot:item.email="{ item }">
              <a :href="'mailto:'+item.email"
                 :style="{maxWidth: '150px'}"
                 class="d-block text-truncate orange--text text-decoration-none">
                {{ item.email }}
              </a>
            </template>

            <template v-slot:item.program="{ item }">
              <v-btn color="primary"
                     depressed
                     small
                     @click="$refs.animatorProgramDialog.open(item,animatorDates)"
              >
                <v-icon left small>mdi-calendar-month</v-icon>
                Programme
              </v-btn>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <AnimatorProgramDialog ref="animatorProgramDialog"/>

  </div>
</template>

<script>
import AnimatorProgramDialog from "@/views/regional-view/campaigns/show/components/AnimatorProgramDialog.vue";

export default {
    components: {AnimatorProgramDialog},
    data() {
        return {
            dialog: false,
            campaign: {},
            animatorDates: [],
            animators: [],
        }
    },
    methods: {
        open(item) {
            this.animators = item.animators
            this.animatorDates = item.dates
            this.dialog = true
        }
    },
    computed: {
        headers() {
            return [
                {text: "Animateur", sortable: false, value: 'name'},
                {text: "N°Téléphone", sortable: false, value: 'phone'},
                {text: "Email", sortable: false, value: 'email'},
                {text: "Programme", sortable: false, value: 'program'},
            ]
        }
    }
}
</script>

<style scoped>

</style>