<template>
  <div>
    <v-container fluid>

      <Overlay :overlay="overlay"/>
      <Lightbox ref="lightbox"/>

      <v-row justify="center">
        <v-col cols="12" lg="10">
          <v-card class="shadow rounded-lg">
            <v-card-title>
              Détails
              <v-spacer/>

              <v-btn class="mr-2"
                     color="primary"
                     exact
                     left
                     text
                     to="/regional-view/campaigns">
                <v-icon color="primary" left>mdi-chevron-left</v-icon>
                Retour
              </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text>

              <div v-if="isLoading">
                <v-card class="rounded-lg mb-3" outlined>
                  <v-card-text>
                    <v-skeleton-loader type="avatar,article"/>
                  </v-card-text>
                </v-card>
                <v-card class="rounded-lg mb-3" outlined>
                  <v-card-text>
                    <v-skeleton-loader type="article,article,article,actions"/>
                  </v-card-text>
                </v-card>
              </div>


              <div v-else>

                <v-card class="rounded-lg mb-3" outlined>
                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="12" lg="8" order="1" order-lg="0">
                        <div class="pb-3 font-weight-medium fs-17 grey--text text--darken-3">
                          {{ campaign.name }}
                        </div>

                        <div class="mb-3">

                          <div class="d-block text-no-wrap">
                            <v-icon color="primary" small>mdi-calendar</v-icon>
                            <span class="font-weight-medium"> Date de début : </span>
                            {{ $func.dateFormat(campaign.start_date) }}
                          </div>

                          <div class="d-block text-no-wrap mt-2">
                            <v-icon color="primary" small>mdi-calendar</v-icon>
                            <span class="font-weight-medium"> Date de fin : </span>
                            {{ $func.dateFormat(campaign.end_date) }}
                          </div>

                          <div v-if="campaign.remaining_days"
                               class="d-block text-no-wrap mt-2">
                            <v-icon color="primary" small>mdi-calendar-minus</v-icon>
                            <span class="font-weight-medium"> Jours restants : </span>
                            <v-chip :class="'bg-'+campaign.remaining_days.color+'-subtle'"
                                    :text-color="campaign.remaining_days.color"
                                    class="font-weight-medium fs-13"
                                    dark
                                    label
                                    small>
                              {{ campaign.remaining_days.value }}
                            </v-chip>
                          </div>

                          <div class="d-block text-no-wrap mt-2">
                            <v-icon color="primary" small>mdi-tag</v-icon>
                            <span class="font-weight-medium"> Type : </span>
                            {{ $func.campaignType(campaign.type) }}
                          </div>

                        </div>

                        <p class="grey--text mb-0">
                          {{ campaign.description }}
                        </p>

                      </v-col>
                      <v-col class="text-center" cols="12" lg="4" order="0" order-lg="1">
                        <v-avatar rounded size="120">
                          <v-img v-if="campaign.image" :src="$baseUrl + campaign.image"
                                 class="zoom-pointer rounded-lg"
                                 max-width="130"
                                 min-height="130"
                                 @click="$refs.lightbox.open(campaign.image)"/>

                          <v-img v-else
                                 :src="require('@/assets/no-image.png')"
                                 class="rounded-lg"
                                 max-width="130"
                                 min-height="130"></v-img>
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-data-table
                        :headers="headers"
                        :items="campaign.supervisors"
                        class="table-border rounded-lg"
                        hide-default-footer
                >

                  <template v-slot:item.name="{ item }">
                    <div class="d-flex align-center py-4">

                      <v-avatar v-if="item.photo" size="40">
                        <v-img :src="$baseUrl + item.photo"
                               class="zoom-pointer"
                               @click="$refs.lightbox.open(item.photo)"></v-img>
                      </v-avatar>

                      <v-avatar v-else
                                :class="$func.randomColor()"
                                class="font-weight-medium"
                                size="40">
                        {{ $func.avatarName(item.name) }}
                      </v-avatar>

                      <span class="ml-2">{{ item.name }}</span>
                    </div>
                  </template>

                  <template v-slot:item.email="{ item }">
                    <a :href="'mailto:'+item.email"
                       :style="{maxWidth: '150px'}"
                       class="d-block text-truncate orange--text text-decoration-none">
                      {{ item.email }}
                    </a>
                  </template>

                  <template v-slot:item.animators="{ item }">
                    <v-btn color="primary"
                           depressed
                           small
                           @click="$refs.animatorsDialog.open(item)"
                    >
                      Animateurs
                      <v-icon right small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </template>

                </v-data-table>

              </div>

            </v-card-text>

          </v-card>

        </v-col>
      </v-row>

      <AnimatorsDialog ref="animatorsDialog"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import AnimatorsDialog from "@/views/regional-view/campaigns/show/components/AnimatorsDialog.vue";

export default {
    components: {AnimatorsDialog},
    data() {
        return {
            campaign: {},
            isLoading: false,
            overlay: false,
            dates: [],
        }
    },

    methods: {
        showCampaign() {
            this.isLoading = true
            HTTP.get('/regional-view/campaigns/' + this.$route.params.id + '/show').then((res) => {
                this.isLoading = false
                this.campaign = res.data.data
            }).catch(err => {
                this.isLoading = false
                console.log(err)

                /**
                 * Not found ID
                 */
                if (err.response.status === 404) {
                    this.$router.push('/404')
                }
            })
        },
    },
    created() {
        this.showCampaign()
    },
    computed: {
        headers() {
            return [
                {text: "Superviseur", sortable: false, value: 'name'},
                {text: "N°Téléphone", sortable: false, value: 'phone'},
                {text: "Email", sortable: false, value: 'email'},
                {text: "Animateurs", sortable: false, value: 'animators'},
            ]
        }
    }
}
</script>

<style scoped>

</style>